<template>
  <b-overlay
    id="overlay-background"
    :show="loading"
    variant="transparent"
    opacity="1"
    blur="2px"
    rounded="sm"
  >
    <b-card>
      <b-table
        striped
        hover
        responsive
        :per-page="500"
        :current-page="1"
        :items="items"
        :fields="fields"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(balance)="data">
          <!-- {{ data.item.balance }} -->
          <b-button
            :variant="getColor(data.item)"
            size="sm"
          >
            {{ data.item.balance }}
          </b-button>
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BTable, BButton
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BCard,
    BTable,
    BButton
  },
  data: () => ({
    loading: true,
    items: [],
    fields: [
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'balance',
        label: 'valor'
      }
    ]
  }),
  async mounted () {
    await this.getBalance()
  },
  methods: {
    async getBalance () {
      this.loading = true
      const url = '/admin/api_balance'

      await this.$http.get(url).then(res => {
        this.items = res.data
      })
        .finally(() => {
          this.loading = false
        })
    },
    getColor (item) {
      if (item.name === 'UP-SEGUIDORES') {
        return this.getColorReal(item.balance)
      }

      if (item.name === 'Drd3m') {
        return this.getColorDolar(item.balance)
      }

      return this.getColorRU(item.balance)
    },
    getColorReal (value) {
      if (value <= 12) return 'relief-danger'
      if (value >= 13 && value <= 29) return 'relief-warning'
      if (value >= 30) return 'relief-success'
    },
    getColorDolar (value) {
      if (value <= 9) return 'relief-danger'
      if (value >= 10 && value <= 15) return 'relief-warning'
      if (value >= 16) return 'relief-success'
    },
    getColorRU (value) {
      if (value <= 500) return 'relief-danger'
      if (value >= 501 && value <= 1000) return 'relief-warning'
      if (value >= 1001) return 'relief-success'
    }
  }
}
</script>
